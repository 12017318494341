import React, { useState, useEffect } from "react";
import { FaWhatsapp, FaPhone, FaMapMarkerAlt, FaTimes } from "react-icons/fa";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import {
  FaBuilding,
  FaRuler,
  FaWarehouse,
  FaShieldAlt,
  FaIndustry,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import main from "../assets/main.jpg";
import building1 from "../assets/b1.jpg";
import building2 from "../assets/b2.jpg";
import building3 from "../assets/b3.jpg";
import building4 from "../assets/b4.jpg";
import building5 from "../assets/b5.jpg";
import building6 from "../assets/b6.jpg";

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const buildingDetails = {
    title: "Warehouse & Office for Rent - Prime Location in Ecotech-6",
    address: "A 50, Ecotech 6, Greater Noida, Uttar Pradesh-201310",
    phone: "+91 9599471583",
    whatsapp: "+919599471583",
    description:
      "Flexible Commercial Property with Modern Facilities - Ideal for Offices, Warehouses, and Workshops",
    mapLink: "https://maps.app.goo.gl/YZ4gPeiNPxMnE76E8",
    mapEmbed:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.5137699300863!2d77.55750197519585!3d28.433924993072456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc10041f096b9%3A0xc65b18f2e257d296!2sMahavedam%20Enterprise%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1738136326030!5m2!1sen!2sin",
  };

  const images = [
    { src: main, title: "Exterior View" },
    { src: building1, title: "" },
    { src: building2, title: "" },
    { src: building3, title: "" },
    { src: building4, title: "" },
    { src: building5, title: "" },
    { src: building6, title: "" },
  ];
  const trackPhoneClick = (url) => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16839293170/4pE6CPvIx5YaEPLxzN0-',
        'value': 1000.0,
        'currency': 'INR',
        'event_callback': () => {
          if (typeof url !== 'undefined') {
            window.location = url;
          }
        }
      });
    }
  };

  const trackWhatsAppClick = (url) => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16839293170/Uev5CP7Ix5YaEPLxzN0-',
        'value': 1000.0,
        'currency': 'INR',
        'event_callback': () => {
          if (typeof url !== 'undefined') {
            window.location = url;
          }
        }
      });
    }
  };

  const handlePhoneClick = (e) => {
    const phoneUrl = `tel:${buildingDetails.phone}`;
    trackPhoneClick(phoneUrl);
  };

  const handleWhatsAppClick = (e) => {
    const whatsappUrl = `https://wa.me/${buildingDetails.whatsapp}`;
    trackWhatsAppClick(whatsappUrl);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [images.length]);

  const navigateGallery = (direction) => {
    if (selectedImage === null) return;
    const newIndex =
      direction === "next"
        ? (selectedImage + 1) % images.length
        : (selectedImage - 1 + images.length) % images.length;
    setSelectedImage(newIndex);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      {/* Hero Section with Auto-Slider */}
      <div className="relative h-screen">
        <div className="relative w-full h-full overflow-hidden">
          <AnimatePresence initial={false}>
            <motion.img
              src={main}
              alt={main.title}
              className="absolute w-full h-full object-fill"
              initial={{ opacity: 0, x: 1000 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="text-center p-8 max-w-4xl">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="text-6xl font-bold mb-6"
            >
              {buildingDetails.title}
            </motion.h1>
            <motion.p
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-xl mb-8 leading-relaxed"
            >
              {buildingDetails.description}
            </motion.p>
            <div className="flex justify-around space-x-4">
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                href={`https://wa.me/${buildingDetails.whatsapp}`}
                onClick={handleWhatsAppClick}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-8 rounded-lg transition-all duration-300"
              >
                <FaWhatsapp className="w-5 h-5 mr-2" />
                Contact on WhatsApp
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                href={`tel:${buildingDetails.phone}`}
                onClick={handlePhoneClick}
                className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition-all duration-300"
              >
                <FaPhone className="w-5 h-5 mr-2" />
                Call Now
              </motion.a>
            </div>
          </div>
        </div>
      </div>
      {/* Floating Contact Buttons (Mobile) */}
      <div className="fixed bottom-4 left-0 right-0 flex justify-between px-8 space-x-4 md:hidden z-50">
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          href={`https://wa.me/${buildingDetails.whatsapp}`}
          onClick={handleWhatsAppClick}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center bg-green-600 text-white px-6 py-3 rounded-full shadow-lg"
        >
          <FaWhatsapp className="w-6 h-6" />
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          href={`tel:${buildingDetails.phone}`}
          onClick={handlePhoneClick}
          className="flex items-center bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg"
        >
          <FaPhone className="w-6 h-6" />
        </motion.a>
      </div>
      {/* Info Cards Section */}
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-xl p-8 mx-auto max-w-4xl -mt-20 relative z-10 text-gray-800">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-8"
          >
            <h3 className="text-3xl font-bold text-gray-900 mb-2">
              Premium Commercial Property
            </h3>
            <p className="text-blue-600 text-xl font-medium">
              Ecotech 6, Greater Noida
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Floor Plans */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-4"
            >
              <h4 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                <FaBuilding className="mr-2 text-blue-600" />
                Available Spaces
              </h4>
              <div className="space-y-4">
                <div className="flex items-start">
                  <FaRuler className="mt-1 mr-3 text-blue-600" />
                  <div>
                    <p className="font-medium">Ground Floor</p>
                    <p className="text-gray-600">
                      2850 sq. ft. (RCC, 14 ft ceiling)
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <FaWarehouse className="mt-1 mr-3 text-blue-600" />
                  <div>
                    <p className="font-medium">First Floor</p>
                    <p className="text-gray-600">
                      3100 sq. ft. (Office + 27 ft high insulated shed)
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <FaBuilding className="mt-1 mr-3 text-blue-600" />
                  <div>
                    <p className="font-medium">Optional Mezzanine Floor</p>
                    <p className="text-gray-600">
                      2400 sq. ft. for extra space
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Features & Benefits */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-4"
            >
              <h4 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                <FaShieldAlt className="mr-2 text-blue-600" />
                Features & Benefits
              </h4>
              <div className="space-y-4">
                <div className="flex items-center">
                  <FaIndustry className="mr-3 text-blue-600" />
                  <p>Fire Safety Work Completed</p>
                </div>
                <div className="flex items-center">
                  <FaBuilding className="mr-3 text-blue-600" />
                  <p>Ideal for corporate offices, warehousing, and workshops</p>
                </div>
                <div className="flex items-center">
                  <FaWarehouse className="mr-3 text-blue-600" />
                  <p>Close to key industrial & commercial hubs</p>
                </div>
                <div className="flex items-center">
                  <FaRuler className="mr-3 text-blue-600" />
                  <p>
                    Flexible rental options – Small & large spaces available
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Call to Action */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mt-12 text-center"
          >
            <p className="text-xl text-blue-600 font-medium mb-6">
              Looking for a warehouse or office space near you? Contact us
              today!
            </p>
          </motion.div>
        </div>
      </div>

      {/* Gallery Carousel Section */}
      <div className="container mx-auto px-4 py-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-3xl font-bold mb-8 text-center"
        >
          Explore Our Facilities
        </motion.h2>

        <div className="relative max-w-5xl mx-auto">
          {/* Main Carousel */}
          <div className="relative overflow-hidden rounded-lg aspect-[16/9]">
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={currentSlide}
                initial={{ opacity: 0, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -300 }}
                transition={{ duration: 0.5 }}
                className="absolute inset-0"
              >
                <img
                  src={images[currentSlide].src}
                  alt={images[currentSlide].title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                  <motion.h3
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="text-white text-2xl font-semibold"
                  >
                    {images[currentSlide].title}
                  </motion.h3>
                </div>
              </motion.div>
            </AnimatePresence>

            {/* Navigation Arrows */}
            <button
              onClick={() =>
                setCurrentSlide(
                  (prev) => (prev - 1 + images.length) % images.length
                )
              }
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all duration-200 z-10"
            >
              <IoArrowBack className="w-6 h-6" />
            </button>
            <button
              onClick={() =>
                setCurrentSlide((prev) => (prev + 1) % images.length)
              }
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white text-gray-800 p-2 rounded-full shadow-lg transition-all duration-200 z-10"
            >
              <IoArrowForward className="w-6 h-6" />
            </button>

            {/* Thumbnail Navigation */}
            <div className="absolute -bottom-16 left-0 right-0 flex justify-center gap-2 mt-4">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-16 h-2 rounded-full transition-all duration-300 ${
                    currentSlide === index
                      ? "bg-blue-600 w-24"
                      : "bg-gray-400 hover:bg-gray-300"
                  }`}
                />
              ))}
            </div>
          </div>

          {/* Thumbnail Strip */}
          <div className="mt-24 p-4 overflow-x-auto">
            <div className="flex gap-4 justify-center">
              {images.map((image, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  onClick={() => setCurrentSlide(index)}
                  className={`relative flex-shrink-0 cursor-pointer transition-all duration-300 ${
                    currentSlide === index
                      ? "ring-4 ring-blue-600 ring-offset-2"
                      : ""
                  }`}
                >
                  <img
                    src={image.src}
                    alt={image.title}
                    className="w-24 h-16 object-cover rounded-lg"
                  />
                  <div
                    className={`absolute inset-0 bg-black rounded-lg transition-opacity duration-300 ${
                      currentSlide === index ? "bg-opacity-0" : "bg-opacity-40"
                    }`}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Lightbox Modal */}
      <AnimatePresence>
        {selectedImage !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setSelectedImage(null)}
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
            >
              <FaTimes className="w-6 h-6" />
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => navigateGallery("prev")}
              className="absolute left-4 text-white hover:text-gray-300 transition-colors"
            >
              <IoArrowBack className="w-8 h-8" />
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => navigateGallery("next")}
              className="absolute right-4 text-white hover:text-gray-300 transition-colors"
            >
              <IoArrowForward className="w-8 h-8" />
            </motion.button>

            <div className="relative max-w-6xl w-full">
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                src={images[selectedImage].src}
                alt={images[selectedImage].title}
                className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
              />
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="text-white text-center mt-4 text-xl font-semibold"
              >
                {images[selectedImage].title}
              </motion.p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Location Section */}
      <div className="bg-gray-900 py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-6"
            >
              <h2 className="text-3xl font-bold mb-6">Strategic Location</h2>

              <div className="flex items-center space-x-3">
                <FaMapMarkerAlt className="w-6 h-6 text-red-400 flex-shrink-0" />
                <a
                  href={buildingDetails.mapLink}
                  className="text-red-400 hover:text-blue-300 text-xl font-semibold"
                >
                  {buildingDetails.address}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <FaPhone className="w-6 h-6 text-blue-400 flex-shrink-0" />
                <a
                  href={`tel:${buildingDetails.phone}`}
                  onClick={handlePhoneClick}
                  className="text-blue-400 hover:text-blue-300 text-xl font-semibold"
                >
                  {buildingDetails.phone}
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <FaWhatsapp className="w-6 h-6 text-green-400 flex-shrink-0" />
                <a
                  href={`https://wa.me/${buildingDetails.whatsapp}`}
                  onClick={handleWhatsAppClick}
                  className="text-green-400 hover:text-blue-300 text-xl font-semibold"
                >
                  {buildingDetails.phone}
                </a>
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="bg-gray-800 p-6 rounded-lg mt-6"
              >
                <h3 className="text-xl font-semibold mb-4">Key Distances</h3>
                <ul className="space-y-3 text-gray-300">
                  <li>• 5 mins from Eastern Peripheral Expressway</li>
                  <li>• 15 mins from Noida Sector 62</li>
                  <li>• 30 mins from Delhi NCR</li>
                  <li>• Easy access to major transportation hubs</li>
                </ul>
              </motion.div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="w-full h-96 rounded-lg overflow-hidden shadow-xl"
            >
              <iframe
                title="Building Map"
                src={buildingDetails.mapEmbed}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-full"
              ></iframe>
            </motion.div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="bg-gray-900 border-t border-gray-800 py-8">
        <div className="container mx-auto px-4">
          <div className=" border-gray-800 text-center text-gray-400">
            <p>
              &copy; {new Date().getFullYear()} {buildingDetails.title}. All
              rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
